<template>
  <v-container
    fluid
    fill-height
    :class="$style.auth"
    class="d-flex justify-center align-center flex-column pa-0"
  >
    <v-overlay :value="processing">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div>
      <v-row dense>
        <v-col cols="12" class="text-center">
          <img :src="require('@/assets/logo.png')" width="100" />
        </v-col>
      </v-row>
      <v-row dense class="mb-4 mt-1">
        <v-col cols="12" class="text-center">
          <h2 :class="$style.title">{{ title }}</h2>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="d-flex justify-center">
          <v-card
            elevation="0"
            color="#fff"
            class="py-0 pa-4 rounded-xl"
            rounded
            :class="$style.form"
            width="360"
          >
            <error-list :errorMessages="errorMessages" :names="['message']" />
            <v-row dense>
              <v-col cols="12">
                <MyForm
                  :formKey="formKey"
                  :entity="entity"
                  :input="input"
                  ref="form"
                >
                  <v-container class="pa-0">
                    <v-row dense>
                      <v-col cols="12">
                        <ValidationProvider
                          v-slot="{ errors }"
                          :rules="{
                            required: 'required'
                          }"
                          name="メールアドレス"
                          vid="mailAddress"
                          ref="v-mailAddress"
                        >
                          <v-text-field
                            v-model="mailAddress"
                            name="mailAddress"
                            label="メールアドレス"
                            clearable
                            autocomplete="off"
                            :error-messages="errors[0]"
                            background-color="#fff"
                          >
                            <v-icon slot="prepend">
                              mdi-email-outline
                            </v-icon>
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12">
                        <ValidationProvider
                          v-slot="{ errors }"
                          :rules="{
                            required: 'required'
                          }"
                          name="パスワード"
                          vid="password"
                          ref="v-password"
                        >
                          <v-text-field
                            v-model="password"
                            name="password"
                            type="password"
                            label="パスワード"
                            clearable
                            autocomplete="off"
                            :error-messages="errors[0]"
                            background-color="#fff"
                          >
                            <v-icon slot="prepend">
                              mdi-lock-outline
                            </v-icon>
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12">
                        <ValidationProvider
                          v-slot="{ errors }"
                          :rules="{
                            required: 'required',
                            confirmed: 'password'
                          }"
                          name="パスワード確認"
                          vid="passwordConfirm"
                          ref="v-passwordConfirm"
                        >
                          <v-text-field
                            v-model="passwordConfirm"
                            name="passwordConfirm"
                            type="password"
                            label="パスワード確認"
                            clearable
                            autocomplete="off"
                            :error-messages="errors[0]"
                            background-color="#fff"
                          >
                            <v-icon slot="prepend">
                              mdi-lock-outline
                            </v-icon>
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-row>
                    <v-col cols="12">
                      <div class="d-flex flex-row align-center ml-1">
                        <v-checkbox
                          v-model="termsOfServiceChecked"
                          name="termsOfServiceChecked"
                          :hide-details="true"
                        >
                          <template v-slot:label>
                            <span class="ml-2 mr-1">{{ labelTerms }}</span>
                          </template>
                        </v-checkbox>
                        <a
                          class="d-block"
                          :class="$style['link']"
                          href="/signup/terms-of-service"
                        >
                          <v-icon>mdi-chevron-right</v-icon>
                        </a>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <div class="d-flex flex-row align-center ml-1">
                        <v-checkbox
                          v-model="privacyPolicyChecked"
                          name="privacyPolicyChecked"
                          :hide-details="true"
                        >
                          <template v-slot:label>
                            <span class="ml-2 mr-1">{{ labelPolicy }}</span>
                          </template>
                        </v-checkbox>
                        <a
                          class="d-block"
                          :class="$style['link']"
                          href="/signup/privacy-policy"
                        >
                          <v-icon>mdi-chevron-right</v-icon>
                        </a>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center">
                    <v-col cols="10">
                      <base-btn
                        :disabled="btnDisabled"
                        label="これで登録する"
                        @click="onCreate"
                        :style="{ color: 'white' }"
                      />
                    </v-col>
                  </v-row>
                </MyForm>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="10">
          <a
            class="text-center d-block"
            :class="$style['link']"
            href="/what-jamid-is"
          >
            <v-icon>mdi-help-circle-outline</v-icon>
            <span class="ml-2 mr-1">jaM idとは</span>
            <v-icon>mdi-chevron-right</v-icon>
          </a>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import MyForm from "@/entries/front/components/form";
import ErrorList from "@/entries/front/components/error/error-list";
export default {
  components: {
    MyForm,
    ErrorList
  },
  data() {
    return {
      formKey: "signup",
      title: "新規アカウント登録",
      input: {},
      createBtnDisabled: true,
      valid: {
        mailAddress: false,
        password: false,
        passwordConfirm: false
      },
      labelTerms: "jaM ID 利用規約",
      labelPolicy: "jaM ID プライバシーポリシー"
    };
  },
  watch: {
    mailAddress(value, old) {
      if (value !== old) {
        this.validate("mailAddress");
      }
    },
    password(value, old) {
      if (value !== old) {
        this.validate("password");
      }
    },
    passwordConfirm(value, old) {
      if (value !== old) {
        this.validate("passwordConfirm");
      }
    }
  },
  computed: {
    mailAddress: {
      get() {
        return this.entity["mailAddress"] || "";
      },
      set(value) {
        this.$store.commit("auth/setEntity", {
          entity: {
            mailAddress: value
          }
        });
      }
    },
    password: {
      get() {
        return this.entity["password"] || "";
      },
      set(value) {
        this.$store.commit("auth/setEntity", {
          entity: {
            password: value
          }
        });
      }
    },
    passwordConfirm: {
      get() {
        return this.entity["passwordConfirm"] || "";
      },
      set(value) {
        this.$store.commit("auth/setEntity", {
          entity: {
            passwordConfirm: value
          }
        });
      }
    },
    termsOfServiceChecked: {
      get() {
        return this.entity["termsOfServiceChecked"];
      },
      set(value) {
        this.$store.commit("auth/setEntity", {
          entity: {
            termsOfServiceChecked: value
          }
        });
      }
    },
    privacyPolicyChecked: {
      get() {
        return this.entity["privacyPolicyChecked"];
      },
      set(value) {
        this.$store.commit("auth/setEntity", {
          entity: {
            privacyPolicyChecked: value
          }
        });
      }
    },
    isError() {
      return this.$store.getters["auth/isError"];
    },
    processing() {
      return this.$store.getters[`auth/processing`];
    },
    entity() {
      return this.$store.getters[`auth/entity`];
    },
    errorMessages() {
      return this.$store.getters[`auth/errorMessages`];
    },
    mergedErrorMessages() {
      return (vvErr) => {
        const messages = [];
        const errorMessages = (this.errorMessages || {}).password || [];
        if (vvErr) {
          messages.push(vvErr);
        }
        if (errorMessages.length) {
          errorMessages.forEach((v) => messages.push(v));
        }
        return messages;
      };
    },
    mergedConfirmErrorMessages() {
      return (vvErr) => {
        console.log(vvErr);
        const messages = [];
        if (vvErr) {
          messages.push(vvErr);
        }
        return messages;
      };
    },
    btnDisabled() {
      const { mailAddress, password, passwordConfirm } = this.valid;
      return !(
        mailAddress &&
        password &&
        passwordConfirm &&
        this.termsOfServiceChecked &&
        this.privacyPolicyChecked
      );
    }
  },
  methods: {
    async validate(refName) {
      const providor = this.$refs[`v-${refName}`];
      const { valid } = await providor.validate();

      this.$nextTick(() => {
        this.valid[refName] = valid;
      });
    },
    onCreate() {
      // this.$store
      //   .dispatch("auth/login", {
      //     mailAddress: this.mailAddress,
      //     password: this.password
      //   })
      //   .then(() => {
      //     this.$store.commit("form/clearDirty");
      //     this.$router.push({ name: "menu" });
      //   })
      //   .catch(() => {});
    },
    popstate() {
      window.history.pushState(null, null, null);
      return;
    }
  },
  created() {
    this.$store.commit(`auth/initialize`);
  },
  mounted() {
    window.addEventListener("popstate", this.popstate);
    this.popstate();
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.popstate);
    this.$store.commit("form/changeDirty", [
      {
        key: this.formKey,
        isDirty: !!this.mailAddress
      }
    ]);
  }
};
</script>

<style module lang="scss">
@import "@/styles/common.scss";
.title {
  font-size: 16px;
  color: #6f808c;
  font-weight: 600;
}

.error {
  color: #ff1100;
  text-align: center;
  font-size: 12px;
}

.link {
  font-size: 16px;
  text-decoration-line: none;
  color: $base-font-color !important;
}
.error-messages {
  margin: 0;
  margin-top: 8px !important;
  padding-left: 46px !important;
  & > li {
    list-style-type: none;
    min-height: 23px;
    line-height: 12px;
    font-size: 12px;
    color: #ff5252 !important;
    caret-color: #ff5252 !important;
  }
}
</style>

<style scope lang="scss">
.theme--light.v-btn.v-btn--disabled {
  color: #fff !important;
}
</style>
